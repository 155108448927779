import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CheckboxModule } from 'primeng/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { ParticipantDisqualifyDemographicComponent } from './participant-disqualify-demographic/participant-disqualify-demographic.component';
// import { ParticipantStudyNotFoundComponent } from './participant-study-not-found/participant-study-not-found.component';

@NgModule({
  declarations: [
    // DemographicFormComponent, 
    // ParticipantHomeComponent,
    // ParticipantStudyDetailComponent,
    // ParticipantStudyDemographicsComponent,
    // ParticipantStudyScreenerComponent
    // ParticipantBonusComponent
    // ParticipantStudyNotFoundComponent
    // ParticipantDisqualifyDemographicComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,      // (Required) for share counts
    //HttpClientJsonpModule, // (Optional) For Tumblr counts
    FontAwesomeModule,
    CheckboxModule
    
  ]
})
export class ParticipantModule { }
