import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { CheckboxModule } from 'primeng/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({ declarations: [
    ], imports: [CommonModule,
        FontAwesomeModule,
        CheckboxModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ParticipantModule { }
